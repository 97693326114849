import React from "react";
import Profile from "../../../Assets/Conversation/Circle.png";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedConversation } from "../../../Redux/Slice/conversationSlice";
import { setSelectChat } from "../../../Redux/Slice/selectedConversation";
import INSTAGRAM from "../../../Assets/Conversation/instagram.png";
import WHATSAPP from "../../../Assets/Conversation/whatsapp.png";
import FACEBOOK from "../../../Assets/Conversation/facebook.png";
import EMAIL from "../../../Assets/Conversation/email.png";
import WEB from "../../../Assets/Conversation/web.png";
import TELEGRAM from "../../../Assets/Conversation/telegram 2.png";

function Conversation({
  chatbot_user,
  latest_message,
  latest_message_time_relative,
  agent,
  id,
  status,
  channel,
  unread_counter,
  flags,
  facebook_name
}) {
  const dispatch = useDispatch();
  const selectChat = useSelector((state) => state.selectChat);
  return (
    <div
      onClick={() => {
        dispatch(
          setSelectChat({
            selectedChat: id,
            name: facebook_name || chatbot_user?.name,
            recordId: agent?.record_id,
            status: status,
          })
        );
      }}
      className="px-[23px] py-[17px] flex flex-col gap-5 cursor-pointer hover:bg-[#F0F2F6]"
      style={{
        borderBottom: "1px solid #F3EDED",
        background: selectChat?.selectedChat === id ? "#F0F2F6" : "#fff",
      }}
    >
      <div className="flex justify-between gap-4 items-start">
        <div className="w-[43px] h-[43px] rounded-full relative">
          <img
            className="rounded-full"
            src={`https://api.dicebear.com/7.x/initials/svg?seed=${facebook_name||chatbot_user?.name}`}
          />
          <div>
            {
              <img
                className="absolute right-0 top-7 bottom-0"
                src={
                  (channel === "WHATSAPP" && WHATSAPP) ||
                  (channel === "INSTAGRAM" && INSTAGRAM) ||
                  (channel === "FACEBOOK" && FACEBOOK) ||
                  (channel === "EMAIL" && EMAIL) ||
                  (channel === "WEB" && WEB) || 
                  (channel === "TELEGRAM" && TELEGRAM)
                }
              />
            }
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-2 ">
          <div className="font-bold text-sm">{facebook_name||chatbot_user?.name}</div>
          {/* <div className="text-[#979797] font-medium text-xs">Live Chat</div> */}
       
        </div>
        <div className="text-[#979797] font-medium text-xs">
          {latest_message_time_relative}
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div
          className="font-bold text-xs"
          style={{
            width: "70%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {latest_message}
        </div>
        <div>
          {/* <div className="w-[21px] h-[21px] bg-[#6FBAC2] rounded-full">
            <img src={Profile} className="w-[21px] h-[21px] rounded-full" />
          </div> */}
               <div className="text-xs p-[2px] bg-[#E8E8E8] text-[#8692A6] font-semibold">
              {
                status === "open" ? "Open" : "Closed"
              }
        </div>
        </div>
      </div>
      <div className="flex justify-between">
        {/* <div className="rounded-sm bg-[#e8e8e8] w-[50px] px-[3px] h-[16px] text-[#8692a6] font-semibold text-xs">
          General
        </div> */}
          <div className="font-bold text-xs ">
        {agent && (
          <>
            Assigned To{" "}
            <span className="rounded-sm bg-[#0766FF] w-[50px] px-[3px] h-[16px] text-[#fff] font-semibold text-xs">
              {agent?.name}
            </span>
            </>
        )}
        </div>
        <div>
          {unread_counter > 0 && (
             <div className="bg-[#E71333] rounded-full w-[30px] h-[20px] flex justify-center items-center">
             <div className="font-normal text-[12px] text-[#fff]">
               {unread_counter}
             </div>
           </div> 
          )}
        </div>
      </div>
      <div className="flex gap-2">
          {
            flags && flags.map((item) => {
              return (
                <div
                  style={{
                    backgroundColor: item.color,
                    display: 'inline-block',
                  }}
                  className={` px-2 rounded-md flex items-center gap-2`}
                >
                  {/* <div key={item.record_id} style={{}}> */}
                    {item.name}
                  {/* </div> */}
                </div>
              );
            })
          }
          </div>
    </div>
  );
}

export default Conversation;
